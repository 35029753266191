html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* ------------------------------------------------------------------------------------*/


body {
    max-width: 100vw;
    font-family: Arial, Helvetica, sans-serif;
	background-color: rgb(235, 235, 235) ;
	color: rgb(149, 149, 149);
	transition: all ease 1s;
	overflow: hidden;
	
}

h1, h3 {
	color:rgb(12, 11, 34)
}

p {
	font-size: 0.8em;
}

.container {
    background-color: white;
    width: 400px;
    margin: 0 auto;
	margin-top: -36px;
	height: 100vh;
}
header{
	width:60%;
	margin: 0 auto;
	margin-bottom: 30px;
	padding-left: 45px;
}
.header-heading {
    font-size: 1.3em;
	line-height: 1.7em;
	padding-top: 60px;

}

.task-count{
    font-size: 0.8em;
	margin-bottom: 20px;
}

.header-p {
	display: inline;
	font-size: 1em;
	margin-right: 15px;

}

.header-dropdown {
	border: none;
	border-bottom: 1px solid rgb(182, 182, 182);
	width: 160px;
}

.header-dropdown:focus {
	outline: none;
}

.header-dropdown option {
	padding: 10px;
}

.task-container {
	display: flex;
	padding: 10px;
	padding-left: 45px;
	height: 45px;
}

.task-container:hover {
	background-color:rgb(241, 241, 241);

}

.task-container:hover svg {
	opacity: 100;

}

.task-container:hover .task-container-checkbox {
	opacity: 100;
}

.task-container input {
	height: 25px;
	width: 25px;
}

.task-text-date {
	width: 100%;
	margin-left: 30px;
	line-height: 1.5em;
}

.task-delete-button {
	padding-right: 30px;
	font-size: 1.5em;
	color: coral;
}

.hidden {
	opacity: 0;
}


.task-list-container {
	overflow: auto;
	max-height: 60vh;
	margin-bottom: 30px;
}


form input {
	border: none;
}

form input:focus {
	outline: none;
}

hr {
	width: 78%;
	margin-left: 48px;
	margin-top: 3px;
}
.user-controls__date {
	background-color: transparent;
	margin-top: 15px;
	padding: 0;
}

.user-controls__date:focus {
	outline: none;
}
.user-controls__date:active {
	outline: none;
}

.user-controls__text {
	margin-left: 50px;
	background: transparent;
	padding: 0;

}
.user-controls__button {
	border: none;
	color: white;
	background-color: coral;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 5px;
	padding: 0px;
	height: 40px;
	width: 80px;
	display: block;
	cursor: pointer;
}

.user-controls__button:hover {
	background-color:rgb(153, 77, 49);
}


.no-tasks {
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
	
}

.completed, .completed:hover {
	background-color: rgb(241, 241, 241);
}


.completed .task-text {
	color: coral;
	text-decoration: line-through;
}

.completed .task-container-checkbox {
	opacity: 0;
}
.toggle-container {
	margin: 10px 0px 0px 10px
}
/* darkmode */

body.dark {
	background-color:rgb(26, 24, 24);
}

body.dark h1, body.dark h3 {
	color: white;
}

body.dark .container {
	background: rgb(36, 35, 35);
	box-shadow: none;
}

body.dark .task-container:hover {
	background-color: rgb(24, 23, 23);
}

body.dark .task-container.completed {
	background-color: rgb(24, 23, 23);
}

body.dark ::placeholder {
	color: white;
}

body.dark .user-controls__text{
	color: white;
}

body.dark .user-controls__date {
	color: white;
}

body.dark ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}